import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';
import Table from 'reactstrap/lib/Table';
import { defaultTo, get } from 'lodash';
import { useTableSort, SORT_ORDER } from 'site-modules/shared/hooks/editorial/use-table-sort';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ShareThisChart } from 'client/site-modules/shared/components/share-this-chart/share-this-chart';
import { CtaButtonText } from 'site-modules/shared/components/native-ad/native-ad-components/cta-button-text/cta-button-text';
import { AdUnit } from 'site-modules/shared/components/ad-unit/ad-unit';
import { bootstrapMigrationLeftRightToStartEnd } from 'client/utils/bootstrap/map-align';
import { compareValues, getTablePropsFromContent } from './article-table-helpers';

import './article-table.scss';

const POSSIBLE_ALIGN = ['left', 'right', 'center'];

export function ArticleTable({ entry, className, tableClassName, shareOptions, isMobile }) {
  const { canonical, pageTitle, contentId } = shareOptions;
  const entryPath = defaultTo(shareOptions.entryPath, []);
  const {
    title,
    creativeId,
    TitleTag,
    adName,
    tableClassName: tableClassNameFromContent,
    stickyFirstColumn,
    rowCount,
    columnCount,
    renderColumnHeader,
    columnCategoriesBackgrounds,
    hasCategories,
    columnHeaderBackgrounds,
    columnWidths,
    fontSize,
    noVerticalBorders,
    noRowHeaders,
    isStriped,
    isSmall,
    columnsAlign,
    headersAlign,
    hasVerticalAlignment,
    categoriesStartIndexes,
    columnCategories,
    verticalAlign,
    logo,
    oemClickOutBtnColor,
    oemClickOutText,
    oemClickOutUrl,
    oemDisplayUrl,
    disclaimer,
  } = getTablePropsFromContent(entry, isMobile);
  const initialRowValues = new Array(rowCount).fill().map((value, index) =>
    entry
      .metadata(`row${index + 1}Values`)
      .value()
      .split('|')
  );
  const initialSortColumn = new Array(columnCount)
    .fill()
    .map((v, index) => index)
    .find(columnIndex => !!entry.metadata(`columnSort${columnIndex + 1}Initial`).value());
  const initialSortOrder = entry.metadata(`columnSort${initialSortColumn + 1}Initial`).value();

  const [rowValues, setRowValues] = useState(
    initialSortOrder
      ? initialRowValues.sort((a, b) =>
          compareValues(a[initialSortColumn], b[initialSortColumn], initialSortOrder === SORT_ORDER.DESCENDING)
        )
      : initialRowValues
  );

  const [sortColumn, sortOrder, onColumnSort] = useTableSort({
    initialSortColumn,
    initialSortOrder,
    rowValues,
    setRowValues,
  });

  const [tableSizes, setTableSizes] = useState({});
  const ref = useRef();

  useEffect(() => {
    setTableSizes({ height: +get(ref, 'current.clientHeight', 0), width: +get(ref, 'current.clientWidth', 0) });
  }, []);

  if (!columnCount) {
    return null;
  }

  const ADS_PROPS = {
    // desktop
    false: { adName, md: true, lg: true, xl: true },
    // mobile
    true: { adName: `m${adName}`, xs: true, sm: true },
  };

  return (
    <div ref={ref} data-tracking-parent={creativeId}>
      <ShareThisChart
        pageTitle={pageTitle}
        pageUrl={canonical}
        shareText="Share this table"
        shareableWidgetData={{
          backLink: canonical,
          defaultTitle: pageTitle,
          widgetName: 'tables',
          hostPageOverride: 'https://www.edmunds.com/widgets/tables/',
          queryParams: { contentId, entryPath: entryPath.join(',') },
          defaultWidth: tableSizes.width,
          defaultHeight: tableSizes.height,
        }}
        className="text-primary-darker text-end ml-auto"
      />
      <div style={{ fontSize }} className={classnames(className, `table-col-count-${columnCount}`)}>
        {!!title && <TitleTag className="heading-3 mb-0_5">{title}</TitleTag>}
        {!!logo && (
          <img
            src={getStaticImageUrl(logo)}
            alt=""
            decoding="async"
            aria-hidden
            loading="lazy"
            className="mt-1 mb-1_5"
            style={{ maxHeight: '40px' }}
          />
        )}
        <Table
          responsive
          striped={isStriped}
          bordered={!noVerticalBorders && !hasCategories}
          size={isSmall ? 'sm' : undefined}
          className={classnames('article-table text-gray-darker', tableClassNameFromContent, tableClassName, {
            'has-categories': hasCategories,
            'sticky-first-column': stickyFirstColumn,
          })}
        >
          {columnWidths.length === columnCount && (
            <colgroup>
              {columnWidths.map((width, index) => {
                const key = `${entry.id}-colgroup-${index}`;

                return <col key={key} width={width} />;
              })}
            </colgroup>
          )}
          {renderColumnHeader && (
            <thead>
              {hasCategories && (
                <tr>
                  {columnCategories.map(({ value, colspan }, index) => {
                    const key = `${entry.id}-category-${index}`;

                    return (
                      <th
                        key={key}
                        colSpan={colspan}
                        scope={colspan === 1 ? 'col' : 'colgroup'}
                        className={classnames('text-center align-middle', { 'category-border': !!index })}
                        {...(columnCategoriesBackgrounds[index]
                          ? { style: { background: columnCategoriesBackgrounds[index] } }
                          : {})}
                      >
                        <ContentFragment>{value}</ContentFragment>
                      </th>
                    );
                  })}
                </tr>
              )}
              <tr>
                {new Array(columnCount).fill().map((value, index) => {
                  const key = `${entry.id}-header-${index}`;
                  const hasAlignment = POSSIBLE_ALIGN.includes(headersAlign[index]);
                  const isSortable = entry.metadata(`columnSort${index + 1}`).boolean();
                  const headerText = (
                    <ContentFragment>{entry.metadata(`columnHeader${index + 1}`).value()}</ContentFragment>
                  );
                  const defaultSortOrder = isSortable ? SORT_ORDER.NONE : null;
                  const hasCategoryBorder = !!index && categoriesStartIndexes.includes(index);

                  return (
                    <th
                      scope="col"
                      className={classnames(
                        'align-middle',
                        `text-${hasAlignment ? bootstrapMigrationLeftRightToStartEnd(headersAlign[index]) : 'center'}`,
                        {
                          'border-start': hasCategories && !!index && !hasCategoryBorder,
                          'category-border': hasCategoryBorder,
                        }
                      )}
                      key={key}
                      aria-sort={sortColumn === index ? sortOrder : defaultSortOrder}
                      {...(columnHeaderBackgrounds[index]
                        ? { style: { background: columnHeaderBackgrounds[index] } }
                        : {})}
                    >
                      {isSortable ? (
                        <button
                          className="sort-btn py-0 pl-0 pr-1_25 pos-r border-0 text-gray-darker fw-bold"
                          onClick={onColumnSort}
                          data-key={index}
                        >
                          {headerText}
                          <i className="arrow icon-arrow-up5" aria-hidden />
                          <i className="arrow icon-arrow-down5" aria-hidden />
                        </button>
                      ) : (
                        headerText
                      )}
                    </th>
                  );
                })}
              </tr>
            </thead>
          )}
          <tbody>
            {rowValues.map((columnValues, index) => {
              const key = `${entry.id}-row-${index}`;

              return (
                <tr key={key}>
                  {columnValues.map((columnValue, columnIndex) => {
                    const columnKey = `${key}-column-${columnIndex}`;
                    const hasAlignment = POSSIBLE_ALIGN.includes(columnsAlign[columnIndex]);
                    const hasCategoryBorder = !!columnIndex && categoriesStartIndexes.includes(columnIndex);

                    let cellProps = { componentToUse: 'td' };
                    if (!noRowHeaders && !columnIndex) {
                      cellProps = { componentToUse: 'th', componentProps: { scope: 'row' } };
                    }

                    return (
                      <ContentFragment
                        {...cellProps}
                        classes={classnames({
                          [`text-${bootstrapMigrationLeftRightToStartEnd(columnsAlign[columnIndex])}`]: hasAlignment,
                          [`align-${verticalAlign}`]: hasVerticalAlignment,
                          'border-start': hasCategories && !!columnIndex && !hasCategoryBorder,
                          'category-border': hasCategoryBorder,
                        })}
                        key={columnKey}
                      >
                        {columnValue}
                      </ContentFragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {!!disclaimer && <div className="small pt-1">{disclaimer}</div>}
        {!!oemClickOutUrl && (
          <div className="text-center mt-2">
            <Button
              tag="a"
              color={oemClickOutBtnColor}
              size="lg"
              href={oemClickOutUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="table-cta-btn fw-medium text-transform-none px-2_5 py-0_75"
              data-tracking-value={oemClickOutText}
            >
              <CtaButtonText text={oemClickOutText} />
            </Button>
            {!!oemDisplayUrl && <div className="mt-0_25 text-blue-40 small">{oemDisplayUrl}</div>}
          </div>
        )}
      </div>
      {!!adName && <AdUnit {...ADS_PROPS[isMobile]} position={`1`} renderWhenViewable={false} refreshable={false} />}
    </div>
  );
}

ArticleTable.propTypes = {
  entry: CmsEntities.Content,
  className: PropTypes.string,
  tableClassName: PropTypes.string,
  shareOptions: PropTypes.shape({}),
  isMobile: PropTypes.bool,
};

ArticleTable.defaultProps = {
  entry: DEFAULT_CONTENT,
  className: null,
  tableClassName: null,
  shareOptions: {},
  isMobile: false,
};
